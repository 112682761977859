import {memo} from 'react';
import {CircleLoader, EntityGrid, EntityGridItem} from '@tehzor/ui-components';
import {ISpace} from '@tehzor/tools/interfaces/spaces/ISpace';
import {Status} from './Status';
import {Area} from './Area';
import {SpaceOwners} from './SpaceOwners';
import {AdditionalName} from './AdditionalName';
import {TypeDecor} from './TypeDecor';
import {useSpacesPermissions} from '@src/core/hooks/permissions/useSpacesPermissions';
import {useSpaceOwning} from '@src/core/hooks/spaces/useSpaceOwning';
import {useIsMutating} from '@tanstack/react-query';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';
import {useTranslation} from 'react-i18next';
import {VisualContentCarousel} from '@tehzor/ui-components/src/components/VisualContentCarousel';
import {getPreparedPlans} from '../../utils/getPreparedPlans';
import {usePlansByIds} from '@src/core/hooks/queries/plans/hooks';
import {getPlansIds} from '../../utils/getPlansIds';
import {useSpacePlanViewer} from '@src/core/hooks/imagesViewers/desktop/useSpacePlanViewer';
import {IndicatorsSelect} from './IndicatorsSelect/IndicatorsSelect';
import {IndicatorsSets} from './IndicatorsSets/IndicatorsSets';
import {useActualUnitIndicatorsSetsExtended} from '@src/core/hooks/queries/unitIndicatorsSets/hooks';
import {useUnitIndicatorsSetsPermissions} from '@src/core/hooks/permissions/useUnitIndicatorsSetsPermissions';
import {useSpaceStatusById} from '@src/core/hooks/queries/spaceStatuses/hooks';

const statusIcon = <i className="tz-status-20" />;
const indicatorIcon = <i className="tz-indicator-20" />;
const spaceTypeIcon = <i className="tz-space-type-20" />;
const areaIcon = <i className="tz-area-20" />;

interface IDesktopInfoProps {
	objectId: string;
	space: ISpace;
	hasUnfixedProblems: boolean;
	hasHandlingOrWaitingProblems: boolean;
	hasUnacceptedCheckItem: boolean;
}

export const Common = memo(
	({
		objectId,
		space,
		hasUnfixedProblems,
		hasHandlingOrWaitingProblems,
		hasUnacceptedCheckItem
	}: IDesktopInfoProps) => {
		const {t} = useTranslation();

		const notBeOwned = useSpaceOwning(space);
		const permissions = useSpacesPermissions(objectId);
		const indicatorsPermissions = useUnitIndicatorsSetsPermissions();
		const inProgress = !!useIsMutating({
			mutationKey: spacesQueryKeys.indicators.edit(),
			predicate: mutation => mutation.state.isPaused !== true
		});
		const plansIds = getPlansIds(space.locations);
		const {data: plans} = usePlansByIds(objectId, plansIds);
		const {fullImagesData, fullUrls, planNames} = getPreparedPlans(plans || []);
		const [viewer, openPlanImage] = useSpacePlanViewer(fullUrls, space.id, fullImagesData);

		const {data: status} = useSpaceStatusById(space.status);
		const {data: indicatorsSets} = useActualUnitIndicatorsSetsExtended(
			objectId,
			undefined,
			false
		);

		return (
			<>
				<EntityGrid withBorders>
					{!!plansIds.length && (
						<EntityGridItem
							fullRow
							className={{content: 'space-page__entity-grid-content'}}
						>
							<div className="space-page__d-images">
								<VisualContentCarousel
									data={fullUrls}
									titles={planNames}
									heightRatio={0.575}
									onClick={openPlanImage}
								/>
							</div>
						</EntityGridItem>
					)}

					{space.altName ? <AdditionalName space={space} /> : null}
					<EntityGridItem
						label={t('spacePage.info.status')}
						icon={statusIcon}
						fullRow
					>
						<Status
							objectId={objectId}
							spaceId={space.id}
							value={space.status}
							permissions={permissions}
							hasUnfixedProblems={hasUnfixedProblems}
							hasHandlingOrWaitingProblems={hasHandlingOrWaitingProblems}
							hasUnacceptedCheckItem={hasUnacceptedCheckItem}
						/>
					</EntityGridItem>
					{indicatorsPermissions.canView && (
						<EntityGridItem
							label={t('spacePage.info.indicators')}
							icon={indicatorIcon}
							className={{content: 'space-page__entity-grid-content-sets'}}
							fullRow
							buttons={
								indicatorsPermissions.canEdit &&
								(!inProgress ? (
									<IndicatorsSelect
										objectId={objectId}
										spaceId={space.id}
										stage={status?.stage}
										value={space.indicators}
									/>
								) : inProgress ? (
									<CircleLoader />
								) : null)
							}
						>
							{space.indicators !== undefined && indicatorsSets && (
								<IndicatorsSets
									indicatorsSets={indicatorsSets}
									value={space.indicators}
								/>
							)}
						</EntityGridItem>
					)}
					{space.typeDecoration !== undefined && (
						<EntityGridItem
							label={t('spacePage.info.typeDecoration')}
							fullRow
							icon={spaceTypeIcon}
						>
							<TypeDecor name={space.typeDecoration} />
						</EntityGridItem>
					)}

					{space.plannedArea || space.actualArea ? (
						<EntityGridItem
							label={t('spacePage.info.plannedArea')}
							fullRow
							icon={areaIcon}
						>
							<Area
								planned={space.plannedArea}
								actual={space.actualArea}
							/>
						</EntityGridItem>
					) : null}

					{!notBeOwned && (
						<SpaceOwners
							objectId={objectId}
							spaceId={space.id}
						/>
					)}
				</EntityGrid>
				{viewer}
			</>
		);
	}
);
