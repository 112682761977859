import {useTranslation} from 'react-i18next';
import {TranslatedFilterPage} from '@src/components/TranslatedFilterPage';
import {FiltersPatternsList} from '@src/components/FiltersPatterns/components/FiltersPattrensList/FiltersPatternsList';
import {IFiltersPattern} from '@src/store/interfaces/IFiltersPattern';
import styles from './FiltersPatternsPage.module.less';
import {useState} from 'react';
import {useTranslatedConfirmDialog} from '@src/core/hooks/translations/useTranslatedConfirmDialog';
import {ChangePatternDialog} from '@src/components/FiltersPatterns/components/ChangePatternDialog/ChangePatternDialog';
import {addSuccessToast} from '@tehzor/ui-components';

interface IFiltersPatternsPageProps {
	applyDisabled: boolean;
	filtersPatterns: IFiltersPattern[];
	selectedPatternId: number | undefined;
	onDeletePattern: (patternId: number) => void;
	onChangePattern: (id: number, name: string) => void;
	onAcceptPattern: () => void;
	onSelectPattern: (pattern: IFiltersPattern) => void;
}

export const FiltersPatternsPage = ({
	applyDisabled,
	filtersPatterns,
	selectedPatternId,
	onDeletePattern,
	onChangePattern,
	onAcceptPattern,
	onSelectPattern
}: IFiltersPatternsPageProps) => {
	const {t} = useTranslation();
	const [patternToChange, setPatternToChange] = useState<IFiltersPattern | undefined>(undefined);

	const [deletePatternDialog, getConfirmation] = useTranslatedConfirmDialog({
		title: 'Удалить шаблон?',
		message: 'Вы уверены, что хотите удалить шаблон фильтров?'
	});

	const handleAcceptPattern = (pattern: IFiltersPattern) => {
		onSelectPattern(pattern);
	};

	const handleApply = () => {
		onAcceptPattern();
	};

	const handleDeletePattern = async (id: number) => {
		if (await getConfirmation()) {
			onDeletePattern(id);
			addSuccessToast({title: 'Шаблон успешно удалён'});
		}
	};

	const handleSetChangedPattern = (pattern: IFiltersPattern | undefined) => {
		setPatternToChange(pattern);
	};

	const handleCloseChangePatternDialog = () => {
		setPatternToChange(undefined);
	};

	const handleChangePattern = (id: number, name: string) => {
		onChangePattern(id, name);
		addSuccessToast({title: 'Шаблон успешно изменён'});
	};

	return (
		<TranslatedFilterPage
			applyDisabled={applyDisabled}
			onApplyClick={handleApply}
			acceptBtnLabel={t('filterPage.acceptBtn.label')}
			className={styles.wrapper}
		>
			<FiltersPatternsList
				patterns={filtersPatterns}
				activePatternId={selectedPatternId}
				onAccept={handleAcceptPattern}
				onChange={handleSetChangedPattern}
				onDelete={handleDeletePattern}
			/>
			{deletePatternDialog}
			<ChangePatternDialog
				patternToChange={patternToChange}
				onChange={handleChangePattern}
				onClose={handleCloseChangePatternDialog}
			/>
		</TranslatedFilterPage>
	);
};
