import {addSuccessToast, IconButton} from '@tehzor/ui-components';
import MenuLayer from '@tehzor/ui-components/src/components/menu/MenuLayer';
import {EmptyMessage} from '@src/components/FiltersPatterns/components/EmptyMessage/EmptyMessage';
import styles from './FiltersPatterns.module.less';
import {AddFiltersPattern} from '@src/components/FiltersPatterns/components/AddFiltersPattern/AddFiltersPattern';
import {FiltersPatternsList} from '@src/components/FiltersPatterns/components/FiltersPattrensList/FiltersPatternsList';
import {useTranslatedConfirmDialog} from '../../core/hooks/translations/useTranslatedConfirmDialog';
import {useCallback, useMemo, useState} from 'react';
import {ChangePatternDialog} from '@src/components/FiltersPatterns/components/ChangePatternDialog/ChangePatternDialog';
import {checkFiltersIsEmpty} from '@src/components/FiltersPatterns/utils/checkFiltersIsEmpty';
import {IFiltersPattern} from '@src/store/interfaces/IFiltersPattern';

interface IFiltersPatternsProps {
	filtersPatterns: IFiltersPattern[];
	activeFiltersPatternId: number | undefined;
	filters: Record<string, unknown>;
	onAddPattern: (name: string, filters: Record<string, unknown>) => void;
	onDeletePattern: (patternId: number) => void;
	onChangePattern: (id: number, name: string) => void;
	onAcceptPattern: (pattern: IFiltersPattern) => void;
}

const iconSettings = <i className="tz-settings" />;

export const FiltersPatterns = ({
	filtersPatterns,
	activeFiltersPatternId,
	filters,
	onAddPattern,
	onDeletePattern,
	onChangePattern,
	onAcceptPattern
}: IFiltersPatternsProps) => {
	const [patternToChange, setPatternToChange] = useState<IFiltersPattern | undefined>(undefined);

	const patternReadyToSave = useMemo(
		() => !activeFiltersPatternId && !checkFiltersIsEmpty(filters),
		[activeFiltersPatternId, filters]
	);

	const [deletePatternDialog, getConfirmation] = useTranslatedConfirmDialog({
		title: 'Удалить шаблон?',
		message: 'Вы уверены, что хотите удалить шаблон фильтров?'
	});

	const handleAddPattern = useCallback(
		(name: string) => {
			onAddPattern(name, filters);
			addSuccessToast({title: 'Шаблон успешно добавлен'});
		},
		[onAddPattern, filters]
	);

	const handleDeletePattern = async (id: number) => {
		if (await getConfirmation()) {
			onDeletePattern(id);
			addSuccessToast({title: 'Шаблон успешно удалён'});
		}
	};

	const handleSetChangedPattern = (pattern: IFiltersPattern | undefined) => {
		setPatternToChange(pattern);
	};

	const handleCloseChangePatternDialog = () => {
		setPatternToChange(undefined);
	};

	const handleChangePattern = (id: number, name: string) => {
		onChangePattern(id, name);
		addSuccessToast({title: 'Шаблон успешно изменён'});
	};

	return (
		<>
			<MenuLayer
				className={styles.layout}
				trigger={
					<IconButton
						type="common"
						className={styles.trigger}
					>
						{iconSettings}
						{patternReadyToSave && <div className={styles.indicator} />}
					</IconButton>
				}
			>
				<div className={styles.wrapper}>
					<div className={styles.header}>
						<p className={styles.title}>Шаблоны фильтров</p>
					</div>
					{patternReadyToSave && <AddFiltersPattern onAddPattern={handleAddPattern} />}
					{filtersPatterns.length ? (
						<FiltersPatternsList
							patterns={filtersPatterns}
							activePatternId={activeFiltersPatternId}
							onAccept={onAcceptPattern}
							onChange={handleSetChangedPattern}
							onDelete={handleDeletePattern}
						/>
					) : (
						!patternReadyToSave && <EmptyMessage />
					)}
				</div>
			</MenuLayer>
			{deletePatternDialog}
			<ChangePatternDialog
				patternToChange={patternToChange}
				onChange={handleChangePattern}
				onClose={handleCloseChangePatternDialog}
			/>
		</>
	);
};
