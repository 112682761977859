import {memo, useCallback, useMemo, useState} from 'react';
import {useUpdateEffect} from 'react-use';
import {FilterButton, TreeSelect} from '@tehzor/ui-components';
import {makeFilterLabel} from '@src/components/EntitiesFilters/utils/makeFilterLabel';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import SelectSearch, {
    treeFilter
} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch';
import {useExtractAllCategoriesAsArray} from '@src/core/hooks/queries/categories/hook';
import {useFilteredCategoriesSetsAsArray} from '@src/core/hooks/queries/categorySets/hook';
import {TranslatedSelectPopup} from '@src/components/TranslatedSelectPopup';
import {useTranslation} from 'react-i18next';
import {makeTreeData} from '@src/core/hooks/queries/categorySets/utils/makeTreeData';

interface ICategoriesSetFilterProps {
    objectsIds?: string[];
    stages?: string[];
    categories?: string[];
    label?: string;
}

export const CategoriesSetFilter = memo(
    ({objectsIds, stages, categories, label}: ICategoriesSetFilterProps) => {
        const {t} = useTranslation();
        const translatedLabel = label ?? t('entitiesFilters.CategoriesSetFilter.label');

        const {dispatch} = useEntitiesFiltersCtx();
        const [selectedCategories, setSelectedCategories] = useState(categories);
        const [selectedCategoriesSets, setSelectedCategoriesSets] = useState<string[]>([]);
        const [expandedCategories, setExpandedCategories] = useState<string[] | undefined>([]);
        const [search, setSearch] = useState('');
        const {data: allCategories} = useExtractAllCategoriesAsArray();
        const {data: categoriesSets} = useFilteredCategoriesSetsAsArray({objectsIds, stages});
        const selectedSetsAndCategories = [
            ...(selectedCategories || []),
            ...selectedCategoriesSets
        ];
        const categoriesSetsIds = useMemo(
            () => (categoriesSets ? categoriesSets.map(set => set.id) : []),
            [categoriesSets]
        );

        const clearSearch = useCallback(() => setSearch(''), []);

        const treeData = useMemo(
            () => makeTreeData(categoriesSets, allCategories),
            [categoriesSets, allCategories]
        );

        const {filteredData, expanded} = useMemo(
            () => treeFilter(treeData, 'content', search),
            [search, treeData]
        );

        useUpdateEffect(() => {
            setExpandedCategories(expanded?.map(item => item.id));
        }, [expanded]);

        const handleApply = useCallback(() => {
            dispatch({categories: selectedCategories});
            clearSearch();
        }, [selectedCategories, dispatch, clearSearch]);

        const handleClear = useCallback(() => {
            setSelectedCategories([]);
            setSelectedCategoriesSets([]);
            clearSearch();
        }, [clearSearch]);

        const handleFullClear = useCallback(() => {
            dispatch({categories: undefined});
            setExpandedCategories([]);
            setSelectedCategories([]);
            setSelectedCategoriesSets([]);
            clearSearch();
        }, [dispatch, clearSearch]);

        const handleCancel = useCallback(() => {
            setSelectedCategories(categories);
            setSelectedCategoriesSets([]);
            clearSearch();
        }, [categories, clearSearch]);

        const handleSelectAll = useCallback(() => {
            clearSearch();
            if (!allCategories || !categoriesSets) return;

            const allCats = categoriesSets.flatMap(
                set => allCategories[set.id]?.map(cat => cat.id) || []
            );
            setSelectedCategories(allCats);
            setSelectedCategoriesSets(categoriesSetsIds);
        }, [categoriesSets, allCategories, clearSearch, categoriesSetsIds]);

        useUpdateEffect(() => {
            setExpandedCategories([]);
            setSelectedCategories(categories);
            if (!categories) {
                setSelectedCategoriesSets([]);
            }
        }, [categories]);

        const handleChangeCategories = useCallback(
            (selected: string[]) => {
                const sets = selected.filter(id => categoriesSetsIds.includes(id));
                const cats = selected.filter(id => !categoriesSetsIds.includes(id));
                setSelectedCategories(cats);
                setSelectedCategoriesSets(sets);
            },
            [categoriesSetsIds]
        );

        return (
            <TranslatedSelectPopup
                onCancel={handleCancel}
                onApply={handleApply}
                onClear={handleClear}
                clearButton={!!selectedCategories?.length}
                count={selectedCategories?.length}
                onSelectAll={handleSelectAll}
                footer
                search={
                    <SelectSearch
                        value={search}
                        placeholder={t('selectSearch.placeholder')}
                        onChange={setSearch}
                        type="popup"
                    />
                }
                trigger={
                    <FilterButton
                        className="entities-filters__item"
                        label={makeFilterLabel(translatedLabel, categories, treeData)}
                        active={!!categories?.length}
                        onClear={handleFullClear}
                    />
                }
            >
                <TreeSelect
                    data={filteredData}
                    multiple
                    value={selectedSetsAndCategories}
                    onChange={handleChangeCategories}
                    expandedValue={expandedCategories}
                    onExpand={setExpandedCategories}
                />
            </TranslatedSelectPopup>
        );
    }
);
