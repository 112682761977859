import {Dispatch, memo, useCallback, useEffect, useState} from 'react';
import './WarrantyClaimForm.less';
import {
	IUploadingFilesAction,
	IUploadingFilesState
} from '@src/core/hooks/states/useUploadingFilesState';
import Attachments from './Attachments/Attachments';
import {EditableDate} from './EditableDate';
import {useSpace} from '@src/core/hooks/queries/space';
import {useExtractSpaceTypesById} from '@src/core/hooks/queries/spaceTypes/hooks';
import {useTranslation} from 'react-i18next';
import {useSpaceOwnersAsMap} from '@src/core/hooks/queries/spaceOwners/hooks';
import {CustomField} from './CustomField/CustomFields';
import {IEntitySettings} from '@src/core/hooks/settings/useEntitySettings';
import {useIsLargeTablet} from '@tehzor/ui-components/src/utils/mediaQueries';
import {useController, useFormContext} from 'react-hook-form';
import {editableWarrantyClaimFields} from '../useEditableWarrantyClaimForm/editableWarrantyClaimFields';
import {AttachmentFileDestination} from '@tehzor/tools/enums/AttachmentFileDestination';
import {SpaceOwner} from './SpaceOwner';
import {ClaimerName} from './ClaimerName';
import {ReplyEmail} from './ReplyEmail';
import {ReplyPhone} from './ReplyPhone';

export type SetValueOptions = Partial<{
	shouldValidate: boolean;
	shouldDirty: boolean;
	shouldTouch: boolean;
}>;

interface IEditableWarrantyClaimProps {
	spaceId?: string;
	objectId?: string;
	uploadingFilesState: IUploadingFilesState;
	uploadingFilesDispatch: Dispatch<IUploadingFilesAction>;
	saving: boolean;
	warrantyClaimId: string | undefined;
	fieldsSettings: IEntitySettings;
	creating?: boolean;
}

/**
 * Интерфейс с визуальными компонентами для редактирования полей
 */

export const WarrantyClaimForm = memo((props: IEditableWarrantyClaimProps) => {
	const {
		spaceId,
		objectId,
		uploadingFilesState,
		uploadingFilesDispatch,
		saving,
		warrantyClaimId,
		fieldsSettings,
		creating
	} = props;

	const {control, reset, setValue} = useFormContext();

	useEffect(() => {
		if (!creating) {
			reset();
			uploadingFilesDispatch({type: 'reset'});
		}
	}, [creating, reset, uploadingFilesDispatch]);

	const changeHandler = useCallback(
		(name: editableWarrantyClaimFields, value: string, options: SetValueOptions) => {
			const defaultOptions = {shouldDirty: true, shouldValidate: true};
			setValue(name, value, options || defaultOptions);
		},
		[setValue]
	);

	const {t} = useTranslation();
	const {data: ownersMap} = useSpaceOwnersAsMap();
	const {data: space} = useSpace(spaceId, objectId);
	const {data: spacesTypes} = useExtractSpaceTypesById();
	const notBeOwned = space ? spacesTypes?.[space.type]?.notBeOwned : undefined;
	const [ownerIsNotClaimer, setOwnerIsNotClaimer] = useState(false);

	const {
		field: {value: spaceOwnerId}
	} = useController({
		name: editableWarrantyClaimFields.SPACE_OWNER,
		control
	});

	const selectedOwner = spaceOwnerId && ownersMap ? ownersMap[spaceOwnerId] : undefined;

	const handleOwnerIsNotClaimer = useCallback(() => {
		setOwnerIsNotClaimer(!ownerIsNotClaimer);
	}, [ownerIsNotClaimer]);

	const hasCustomFields = !!Object.keys(fieldsSettings.custom).length;
	const isDesktop = useIsLargeTablet();

	return (
		<div className="editable-warranty-claim">
			<div>
				<div className="editable-warranty-claim__info-grid">
					{spaceId && !notBeOwned ? (
						<SpaceOwner
							data-testid="EditableWarrantyClaimSpaceOwner"
							name={editableWarrantyClaimFields.SPACE_OWNER}
							onSetValue={changeHandler}
							spaceId={spaceId}
							required
							disabled={saving}
							ownerIsNotClaimer={ownerIsNotClaimer}
							handleOwnerIsNotClaimer={handleOwnerIsNotClaimer}
							label={
								ownerIsNotClaimer
									? t('components.editableWarrantyClaim.spaceOwner.client.label')
									: t(
											'components.editableWarrantyClaim.spaceOwner.clientApplicant.label'
									  )
							}
						/>
					) : null}

					{!spaceId || notBeOwned || ownerIsNotClaimer ? (
						<ClaimerName
							data-testid="EditableEntityGridCell"
							label={t('editableWarrantyClaim.claimerName.label')}
							name={editableWarrantyClaimFields.CLAIMER_NAME}
							onSetValue={changeHandler}
							required
							disabled={saving}
							defaultValue={selectedOwner?.name}
						/>
					) : null}

					<ReplyEmail
						data-testid="EditableEntityGridCell"
						required
						name={editableWarrantyClaimFields.REPLY_EMAIL}
						onSetValue={changeHandler}
						disabled={saving}
						defaultValue={selectedOwner?.email}
					/>

					<ReplyPhone
						data-testid="EditableEntityGridCell"
						label={t('editableWarrantyClaim.replyPhone.label')}
						name={editableWarrantyClaimFields.REPLY_PHONE}
						onSetValue={changeHandler}
						required
						disabled={saving}
						defaultValue={selectedOwner?.phone}
					/>

					<EditableDate
						data-testid="EditableEntityGridCell"
						label={t('editableWarrantyClaim.editableDateField.label')}
						name={editableWarrantyClaimFields.REGISTRATION_DATE}
						disabled={saving}
						onSetValue={changeHandler}
					/>

					{hasCustomFields &&
						Object.values(fieldsSettings.custom).map(customSetting => (
							<CustomField
								key={customSetting.id}
								custom={customSetting}
								name={`${editableWarrantyClaimFields.CUSTOM_FIELDS}.${customSetting.fieldKey}`}
								isMobile={!isDesktop}
								onSetValue={changeHandler}
							/>
						))}
				</div>
			</div>

			<Attachments
				data-testid="EditableWarrantyClaimAttachments"
				name={editableWarrantyClaimFields.ATTACHMENTS}
				className={{
					root: 'editable-warranty-claim__attachments',
					scrollArea: 'editable-warranty-claim__attachments-scroll-area',
					files: 'editable-warranty-claim__attachments-files',
					file: 'editable-warranty-claim__attachments-file'
				}}
				attachmentsDestination={AttachmentFileDestination.WARRANTY_CLAIM}
				entityId={warrantyClaimId}
				uploadingFilesDispatch={uploadingFilesDispatch}
				uploadingFilesState={uploadingFilesState}
				waitForUploading={false}
				label={t('editableWarrantyClaim.attachments.label')}
				disabled={saving}
				canDraw={false}
				showAttachBtn
				onSetValue={changeHandler}
			/>
		</div>
	);
});
