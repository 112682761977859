import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import {
	IProblemsFiltersState,
	IProblemsPageSettingsState,
	IProblemsPagesSettingsState,
	IProblemsSortState
} from './interfaces';
import {IChangeFiltersPayload} from '@src/store/interfaces/IChangeFiltersPayload';
import {IClearFiltersPayload} from '@src/store/interfaces/IClearFiltersPayload';
import {IChangeSortPayload} from '@src/store/interfaces/IChangeSortPayload';
import {IChangePageSizePayload} from '@src/store/interfaces/IChangePageSizePayload';
import {ITableSettings} from '@src/interfaces/ITableSettings';
import {ProblemSearchField} from '@src/constants/filters/searchFields';

const initialState: IProblemsPagesSettingsState = {};

export const getInitialStateForPage = (): IProblemsPageSettingsState => ({
	filters: {},
	sort: {
		modifiedAt: false
	},
	pageSize: 20,
	offset: 0,
	selectedRows: [],
	tableSettings: {},
	searchBy: ProblemSearchField.Description,
	filtersPatterns: [],
	activeFiltersPatternId: undefined
});

export const problemsSlice = createSlice({
	name: 'problems',
	initialState,
	reducers: {
		changeFilters(state, action: PayloadAction<IChangeFiltersPayload<IProblemsFiltersState>>) {
			const {objectId, filters} = action.payload;
			if (!state.hasOwnProperty(objectId)) {
				state[objectId] = getInitialStateForPage();
			}
			state[objectId].filters = filters;
			state[objectId].activeFiltersPatternId = undefined;
		},
		clearFilters(state, action: PayloadAction<IClearFiltersPayload>) {
			const {objectId, isDescendants} = action.payload;
			state[objectId].filters = {};
			if (objectId !== 'all' && !isDescendants) {
				state[objectId].filters = {objects: [objectId]};
			}
			state[objectId].activeFiltersPatternId = undefined;
		},
		changeSort(state, action: PayloadAction<IChangeSortPayload<IProblemsSortState>>) {
			const {objectId, sort} = action.payload;
			if (!state.hasOwnProperty(objectId)) {
				state[objectId] = getInitialStateForPage();
			}
			state[objectId].sort = sort;
		},
		changePageSize(state, action: PayloadAction<IChangePageSizePayload>) {
			const {objectId, pageSize} = action.payload;
			if (!state.hasOwnProperty(objectId)) {
				state[objectId] = getInitialStateForPage();
			}
			state[objectId].pageSize = pageSize;
		},
		changeOffset(state, action: PayloadAction<{objectId: string; offset: number}>) {
			const {objectId, offset} = action.payload;
			if (!state.hasOwnProperty(objectId)) {
				state[objectId] = getInitialStateForPage();
			}
			state[objectId].offset = offset;
		},
		changeSelectedRows(
			state,
			action: PayloadAction<{objectId: string; selectedRows: string[]}>
		) {
			const {objectId, selectedRows} = action.payload;

			if (!state.hasOwnProperty(objectId)) {
				state[objectId] = getInitialStateForPage();
			}
			state[objectId].selectedRows = selectedRows;
		},
		changeTableSettings(
			state,
			action: PayloadAction<{objectId: string; tableSettings: ITableSettings}>
		) {
			const {objectId, tableSettings} = action.payload;
			if (!state.hasOwnProperty(objectId)) {
				state[objectId] = getInitialStateForPage();
			}
			state[objectId].tableSettings = tableSettings;
		},
		changeSearchBy(state, action: PayloadAction<{objectId: string; searchBy: string}>) {
			const {objectId, searchBy} = action.payload;
			if (!state.hasOwnProperty(objectId)) {
				state[objectId] = getInitialStateForPage();
			}
			state[objectId].searchBy = searchBy;
		},
		changeColumnVisible(state, action: PayloadAction<{objectId: string; columnKey: string}>) {
			const {objectId, columnKey} = action.payload;
			state[objectId].tableSettings[columnKey].isVisible =
				!state[objectId].tableSettings[columnKey].isVisible;
		},
		showAllColumn(state, action: PayloadAction<{objectId: string}>) {
			const {objectId} = action.payload;
			for (const key in state[objectId].tableSettings) {
				if (state[objectId].tableSettings.hasOwnProperty(key)) {
					state[objectId].tableSettings[key].isVisible = true;
				}
			}
		},
		addFiltersPattern(
			state,
			action: PayloadAction<{
				objectId: string;
				name: string;
				filters: Record<string, unknown>;
				activate?: boolean;
			}>
		) {
			const {objectId, name, filters, activate = true} = action.payload;
			const currentState = state[objectId];
			const newPattern = {
				id: Date.now(),
				name,
				filters
			};
			currentState.filtersPatterns = [...currentState.filtersPatterns, newPattern];
			if (activate) {
				currentState.activeFiltersPatternId = newPattern.id;
			}
		},
		deleteFiltersPattern(state, action: PayloadAction<{objectId: string; id: number}>) {
			const {objectId, id} = action.payload;
			const currentState = state[objectId];
			if (currentState.activeFiltersPatternId === id) {
				currentState.activeFiltersPatternId = undefined;
			}
			currentState.filtersPatterns = currentState.filtersPatterns.filter(
				pattern => pattern.id !== id
			);
		},
		changeFiltersPattern(
			state,
			action: PayloadAction<{objectId: string; id: number; name: string}>
		) {
			const {objectId, id, name} = action.payload;
			const currentState = state[objectId];
			currentState.filtersPatterns = currentState.filtersPatterns.map(pattern =>
				pattern.id === id
					? {
							...pattern,
							name
					  }
					: pattern
			);
		},
		acceptFiltersPattern(state, action: PayloadAction<{objectId: string; id: number}>) {
			const {objectId, id} = action.payload;
			const currentState = state[objectId];
			const pattern = currentState.filtersPatterns.find(pattern => pattern.id === id);
			if (pattern) {
				currentState.filters = pattern.filters;
				currentState.activeFiltersPatternId = pattern.id;
			}
		},
		changeActiveFiltersPatternId(
			state,
			action: PayloadAction<{objectId: string; id: number | undefined}>
		) {
			const {objectId, id} = action.payload;
			state[objectId].activeFiltersPatternId = id;
		}
	}
});
export const {actions: problemsActions, reducer: problemsReducer} = problemsSlice;
